import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import vuetify from './plugins/vuetify'
import Notification from "@kyvg/vue3-notification"
import { loadFonts } from './plugins/webfontloader'
import './assets/fonts.sass'
import './assets/style.sass'

loadFonts()

createApp(App)
  .use(router)
  .use(Notification)
  .use(store)
  .use(vuetify)
  .mount('#app')
