<template>
  <div class="main d-flex flex-column justify-center align-center">
    <h1 class="main-title">ЭКО ФРЕЙТ — МНОГОПРОФИЛЬНАЯ КОМПАНИЯ</h1>
    <h2 class="main-subtitle">Мы не ограничены в какой-либо сфере бизнеса</h2>
    <button class="main-btn" @click="isModal = !isModal">Заказать звонок</button>
  </div>
  <div class="modal" v-if="isModal">
    <v-form
        lazy-validation
        class="form"
    >
      <button class="modal-close d-flex align-center flex-column" @click="isModal = !isModal">
        <span></span>
        <span></span>
      </button>
      <div class="form-title mb-3">Закажите звонок, наш специалист свяжется в вами:</div>
      <v-text-field
          bg-color="#000000"
          label="Контактное лицо"
          v-model="name"
          prepend-inner-icon="mdi-account"
          :rules="[rules.required]"
      ></v-text-field>
      <v-text-field
          bg-color="#000000"
          label="E-mail"
          v-model="email"
          prepend-inner-icon="mdi-email"
          :rules="[rules.required, rules.email]"
      ></v-text-field>
      <v-text-field
          bg-color="#000000"
          label="Телефон"
          v-model="phone"
          prepend-inner-icon="mdi-phone"
          :rules="[rules.required, rules.phone]"
      ></v-text-field>
      <div class="d-flex align-start mb-sm-0 mb-3">
        <v-checkbox
            v-model="checkbox"
            hide-details
            class="mr-xl-3 mr-lg-6 mr-sm-3 mr-6"
        ></v-checkbox>
        <span>Я даю свое согласение на обработку персональных данных согласно Политике конфиденциальности</span>
      </div>
      <vue-recaptcha class="captcha" ref="recaptcha" sitekey="6LeGaLkhAAAAACZDmppbWig5utIneQ2AWqAFOmaP" @expired="onCaptchaExpired" @verify="captchaVerify"></vue-recaptcha>
      <v-btn class="form-btn d-flex" :disabled="!checkbox" @click.prevent="sendForm">Отправить</v-btn>
    </v-form>
  </div>
</template>

<script>


import axios from "axios";
import {BASE_URL} from "../../server/keys";
import { VueRecaptcha } from 'vue-recaptcha';

export default {
  name: "AppPreview",
  components: { VueRecaptcha },
  data () {
    return {
      rules: {
        required: value => !!value || 'Поле обязательное',
        email: value => {
          const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
          return pattern.test(value) || 'Нужно указать email'
        },
        phone: value => {
          const pattern = /^[\\+]?[(]?[0-9]{3}[)]?[-\s\\.]?[0-9]{3}[-\s\\.]?[0-9]{4,6}$/im
          return pattern.test(value) || 'Неправильный номер'
        }
      },
      isModal: false,
      checkbox: false,
      name: '',
      email: '',
      phone: '',
      captchaToken: '',
    }
  },
  methods: {
    async sendForm () {
      if (this.captchaToken) {
        await axios({
        url: BASE_URL + 'api/mail/send/modal',
        method: 'post',
        data: {
          name: this.name,
          email: this.email,
          phone: this.phone,
          checkbox: this.checkbox
        }
      }).then(() => {
        this.name = null
        this.email = null
        this.phone = null
        this.checkbox = false
        this.$notify({ type: 'success', text: 'Сообщение отправлено, вскоре с вами свяжемся' })
      }).catch((err) => {
        console.log(err)
        this.$notify({ type: 'error', text: 'Сообщение не отправлено, попробуйте еще раз' })
      })
      }
    },
    captchaVerify(response) {
      this.captchaToken = response;
    },
    onCaptchaExpired () {
      this.$refs.recaptcha.reset();
      this.captchaToken = ''
    }
  }
}
</script>

<style scoped lang="sass">
.modal
  position: fixed
  left: 0
  top: 0
  width: 100%
  height: 100vh
  background: rgba(0, 0, 0, 0.7)
  display: flex
  justify-content: center
  align-items: center
  z-index: 100
  .form
    background: #212121
    padding: 44px
    border-radius: 8px
    color: rgba(255, 255, 255, 0.5)
    width: 40%
    position: relative
    &-btn
      background-color: #0047CD
      font-family: 'Montserrat Regular'
      font-style: normal
      font-weight: 400
      font-size: 20px
      line-height: 26px
      color: #FFFFFF
      padding: 25px 60px
    .modal-close
      height: 44px
      width: 44px
      padding: 10px
      position: absolute
      right: 0
      top: 0
      cursor: pointer
      z-index: 1000
      span
        background: #ffffff
        height: 2px
        border-radius: 2px
        &:first-child
          transform: rotate(45deg)
          position: absolute
          width: 80%
          top: 50%
        &:last-child
          transform: rotate(-45deg)
          position: absolute
          width: 80%
          top: 50%
.main
  background: url("@/assets/images/backgrounds/main-bg.png") center center/cover no-repeat
  height: 100vh
  &-title
    font-family: 'Manrope'
    font-style: normal
    font-weight: 700
    font-size: 42px
    line-height: 57px
    text-align: center
    color: #FFFFFF
    margin-bottom: 16px
  &-subtitle
    font-family: 'Manrope'
    font-style: normal
    font-weight: 500
    font-size: 28px
    line-height: 38px
    text-align: center
    color: #FFFFFF
    margin-bottom: 60px
  &-btn
    background: #0047CD
    box-shadow: 0px 4px 10px rgba(255, 255, 255, 0.08)
    border-radius: 4px
    padding: 20px 50px
    font-family: "Montserrat Regular"
    font-style: normal
    font-weight: 400
    font-size: 26px
    line-height: 25px
    color: #FFFFFF
@media (max-width: 1279px)
  .main
    &-title
      padding: 0 10px
  .modal
    .form
      width: 60%
@media (max-width: 959px)
  .modal
    .form
      width: 80%
@media (max-width: 599px)
  .g-recaptcha, .g-recaptcha div
    overflow: hidden
    max-width: 100%
  .captcha
    transform: scale(0.77)
    -webkit-transform: scale(0.77)
    transform-origin: 0 0
    -webkit-transform-origin: 0 0
  .main
    &-title
      font-weight: 600
      font-size: 26px
      line-height: 36px
    &-subtitle
      font-weight: 400
      font-size: 20px
      line-height: 27px
    &-btn
      font-weight: 400
      font-size: 22px
      line-height: 25px
      padding: 18px 60px
  .modal
    .form
      padding: 10px
      width: 90%
      &-title
        font-size: 13px
      span
        font-size: 13px
</style>