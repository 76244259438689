// eslint-disable-next-line no-unused-vars
import { signInWithEmailAndPassword, signOut, onAuthStateChanged } from 'firebase/auth'
import { auth } from '@/firebase'


export default {
  actions: {
    async login ({ commit }, { email, password }) {
      try {
        await signInWithEmailAndPassword(auth, email, password)
        commit('clearError')
      } catch (e) {
        commit('setError', e)
        throw e
      }
    },
    // eslint-disable-next-line no-unused-vars
    async logout ({ commit }) {
      await signOut(auth)
    }
  }
}

