import { initializeApp } from 'firebase/app'
import { getFirestore } from 'firebase/firestore'
import { getAuth } from 'firebase/auth'
import { getStorage } from 'firebase/storage'

const firebaseConfig = {
    apiKey: "AIzaSyBD6I0rsP-jf-OWj5BWcgEpH1zlPtwXMuo",
    authDomain: "ecofreight-f0e7e.firebaseapp.com",
    projectId: "ecofreight-f0e7e",
    storageBucket: "ecofreight-f0e7e.appspot.com",
    messagingSenderId: "511222798146",
    appId: "1:511222798146:web:46d68ce2213a56039e1adb"
};

// measurementId: 'G-6238PRH8EH'

const app = initializeApp(firebaseConfig)

const db = getFirestore(app)
const auth = getAuth(app)
const storage = getStorage(app)


export {
    app,
    db,
    auth,
    storage
}