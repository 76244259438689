<template>
  <v-container>
    <v-row>
      <v-col
      xl="11"
      offset-xl="1"
      lg="12"
      offset-lg="0"
      cols="11"
      offset="1"
      >
        <div class="services-title">Услуги <div></div></div>
      </v-col>
      <v-col
          xl="10"
          lg="12"
          offset="0"
          offset-xl="1"
          class="d-flex flex-wrap justify-center"
      >
        <v-row>
          <v-col
              cols="12"
              lg="6"
              v-for="service of services"
              :key="service.name"
              class="d-flex justify-center px-0"
          >
            <div class="card d-flex">
              <div class="card-content order-sm-1 order-2">
                <div class="card-content__title">{{ service.name }}</div>
                <div class="card-content__description">{{ service.desc }}</div>
                <router-link v-if="!isAdmin" class="card-content__btn" :to="'/services/' + service.nameEng">Подробнее</router-link>
                <v-btn class="btn-edit" v-if="isAdmin" @click="editService(service)">Редактировать</v-btn>
                <v-btn class="btn-remove" v-if="isAdmin" @click="removeService(service.id)">Удалить</v-btn>
              </div>
              <div class="card-img order-sm-2 order-1">
                <img :src="service.imageUrl" alt="img">
              </div>
            </div>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  name: "AppServices",
  props: ['services'],
  data () {
    return {

    }
  },
  methods: {
    async removeService (id) {
      try {
        await this.$store.dispatch('deleteService', id)
      } catch (e) {
        console.log(e)
      }
    },
    editService(service) {
        this.$router.push({
        name: 'add-service',
        params: {service: JSON.stringify(service)}
      });
    }
  },
  computed: {
    isAdmin () {
      return this.$route.path.includes('/admin')
    }
  }
}
</script>

<style scoped lang="sass">
.btn-edit
  background: #0047CD
  color: #ffffff
.btn-remove
  background: #a30303
  color: #ffffff
.services-title
  font-family: 'Manrope'
  font-style: normal
  font-weight: 700
  font-size: 32px
  line-height: 44px
  text-transform: uppercase
  color: #000000
  margin-top: 60px
  margin-left: 22px
  div
    width: 90px
    height: 4px
    background-color: #0047CD
    border-radius: 2px
.card
  background: #EDEDED
  box-shadow: 0px 4px 10px 2px rgba(0, 0, 0, 0.15)
  border-radius: 6px
  padding-left: 40px
  &-content
    display: flex
    flex-direction: column
    justify-content: space-between
    width: 400px
    padding-right: 20px
    &__title
      font-family: 'Montserrat Light'
      font-style: normal
      font-weight: 300
      font-size: 32px
      line-height: 39px
      color: #000000
      margin-top: 32px
    &__description
      font-family: 'Montserrat Light'
      font-style: normal
      font-weight: 400
      font-size: 15px
      line-height: 25px
      color: #000000
      width: 100%
      margin-top: 12px
      padding-right: 10px
    &__btn
      padding: 20px 80px
      border: 1px solid #000000
      border-radius: 4px
      font-family: 'Montserrat Light'
      font-style: normal
      font-weight: 400
      font-size: 20px
      line-height: 25px
      color: #000000
      text-decoration: none
      margin-top: 24px
      margin-bottom: 40px
      max-width: 280px
  &-img
    max-width: 230px
    img
      height: 100%
      object-fit: cover
      width: 100%
      border-radius: 0 6px 6px 0
@media (max-width: 1919px)
  .services-title
    margin-left: 0
  .card
    &-content
      max-width: 310px
      &__title
        font-size: 28px
      &__subtitle
        width: 100%
@media (max-width: 1279px)
  .services-title
    margin-top: 40px
  .card
    width: 82%
    justify-content: space-between
    &-content
      max-width: unset
    &-img
      max-width: 280px
@media (max-width: 599px)
  .services-title
    margin-top: 20px
  .card
    flex-direction: column
    padding-left: 0
    &-content
      padding: 0 20px
      width: unset
      &__title
        font-family: "Montserrat Bold"
        font-weight: 600
        font-size: 20px
        line-height: 24px
        color: #000000
      &__description
        font-family: "Montserrat Regular"
        font-weight: 400
        font-size: 15px
        line-height: 25px
        color: #000000
      &__btn
        display: flex
        justify-content: center
        font-family: "Montserrat Regular"
        font-weight: 400
        font-size: 20px
        line-height: 25px
        color: #000000
    &-img
      max-height: 250px
      max-width: 100%
      img
        object-fit: fill
        border-radius: 6px 6px 0 0
</style>