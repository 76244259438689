import {doc, addDoc, deleteDoc, getDocs, collection, updateDoc, where, query} from 'firebase/firestore'
import {ref, getDownloadURL} from 'firebase/storage'
import {db, storage} from '@/firebase'
import { rusToLat } from '../../plugins/stringtranslate'

export default {
  actions: {
    //получаем массив со всеми новостями
    async fetchNews({commit}) {
      try {
        const querySnapshot = await getDocs(collection(db, "news"));
        const news = querySnapshot.docs.map((d) => ({...d.data(), id: d.id}));
        const downloadImageUrlsRequests = news.map(x => getDownloadURL(ref(storage, x.image)));
        const urls = await Promise.all(downloadImageUrlsRequests);
        return news.map((n, i) => ({...n, imageUrl: urls[i]}));
      } catch (e) {
        commit('setError', e)
        throw e
      }
    },
    //получаем массив новостей с фильтром по title
    // eslint-disable-next-line no-unused-vars
    async fetchNewsByName({commit}, name) {
      try {
        const newsRef = collection(db, "news");
        const q = query(newsRef, where("title", "==", name));
        const querySnapshot = await getDocs(q);
        const news = querySnapshot.docs.map((d) => ({...d.data(), id: d.id}));
        const downloadImageUrlsRequests = news.map(x => getDownloadURL(ref(storage, x.image)));
        const urls = await Promise.all(downloadImageUrlsRequests);
        return news.map((n, i) => ({...n, imageUrl: urls[i]}))?.[0] || null;
      } catch (e) {
        commit('setError', e)
        throw e
      }
    },
    // eslint-disable-next-line no-unused-vars
    async updateNews({commit, dispatch}, {id, docData}) {
      try {
        const docRef = doc(db, "news", id);
        await updateDoc(docRef, {...docData, nameEng: rusToLat(docData.title)});
        return {...docData, id, nameEng: rusToLat(docData.title)}
      } catch (e) {
        commit('setError', e)
        throw e
      }
    },
    //создание новости с автоматическим присвоением id. Если требуется указать свой id, тогда используйте setDoc
    async createNews({commit}, docData) {
      try {
        const docRef = await addDoc(collection(db, "news"), {...docData, nameEng: rusToLat(docData.title)});
        return {...docData, id: docRef.id, nameEng: rusToLat(docData.title)}
      } catch (e) {
        commit('setError', e)
        throw e
      }
    },
    async deleteNews({commit}, id) {
      try {
        await deleteDoc(doc(db, "news", id));
      } catch (e) {
        commit('setError', e)
        throw e
      }
    }
  }
}
