<template>
  <v-app>
    <v-navigation-drawer permanent v-if="!isEdit">
      <div class="title">Панель управления</div>
      <div class="d-flex flex-column">
        <v-menu>
          <template v-slot:activator="{ props }">
            <v-btn
                v-bind="props"
            >
              Управление услугами
            </v-btn>
          </template>

          <v-list>
            <v-list-item
                v-for="(item, index) in serviceList"
                :key="index"
            >
              <router-link :to="item.to"><v-list-item-title>{{ item.title }}</v-list-item-title></router-link>
            </v-list-item>
          </v-list>
        </v-menu>
        <v-menu>
          <template v-slot:activator="{ props }">
            <v-btn
                v-bind="props"
            >
              Управление новостями
            </v-btn>
          </template>

          <v-list>
            <v-list-item
                v-for="(item, index) in newsList"
                :key="index"
            >
              <router-link :to="item.to"><v-list-item-title>{{ item.title }}</v-list-item-title></router-link>
            </v-list-item>
          </v-list>
        </v-menu>
      </div>
    </v-navigation-drawer>
    <router-view></router-view>
  </v-app>
</template>

<script>
export default {
  name: "AdminView",
  data () {
    return {
      serviceList: [
        { title: 'Добавить услугу', to: '/admin/add-service' },
        { title: 'Редактировать услуги', to: '/admin/edit-service' }
      ],
      newsList: [
        { title: 'Добавить новость', to: '/admin/add-news' },
        { title: 'Редактировать новости', to: '/admin/edit-news' }
      ]
    }
  },
  computed: {
    isEdit () {
      return this.$route.path.includes('/admin/edit-service') || this.$route.path.includes('/admin/edit-news')
    }
  }
}
</script>

<style scoped lang="sass">
.title
  color: #ffffff
  text-decoration: none
  font-family: "Manrope"
  font-weight: 400
  font-size: 26px
  line-height: 24px
  text-align: center
  padding: 12px 0
  margin-bottom: 10px
.v-btn
  background: transparent
  border-radius: 0
  box-shadow: none
  color: #ffffff
.v-list-item
  a
    text-decoration: none
    color: #000000
</style>