<template>
  <div class="navbar d-md-flex d-none justify-space-around align-center">
    <div class="navbar-logo">
      <router-link to="/"><img :src="require('@/assets/logo.jpg')" alt="logo" height="50"></router-link>
    </div>
    <div class="navbar-links h-100 d-flex">
      <router-link class="d-flex align-center h-100 navbar-link" v-for="link of navbarLinks" :key="link" :to="link.href">{{ link.name }}</router-link>
    </div>
    <div class="navbar-contacts" v-for="contact of navbarContacts" :key="contact">
      <a class="d-flex align-center" :href="contact.href">
        <v-icon
            :icon="contact.icon"
        ></v-icon>
        {{ contact.text }}
      </a>
    </div>
  </div>
  <div class="mobile-navbar d-md-none d-flex">
    <transition name="slide-fade">
      <div class="navbar-logo navbar-logo__mobile d-sm-flex d-none" v-if="!drawer">
        <a href="#"><img :src="require('@/assets/logo.jpg')" alt="logo" height="50" width="100"></a>
      </div>
    </transition>
    <button class="mobile-navbar__hamburger d-flex flex-column justify-space-between" @click="drawer = !drawer">
      <span></span>
      <span></span>
      <span></span>
    </button>
    <transition name="slide-fade">
      <div class="mobile-navbar__drawer d-flex align-center flex-column" v-if="drawer">
        <button class="mobile-navbar__close d-flex align-center flex-column" @click="drawer = !drawer">
          <span></span>
          <span></span>
        </button>
        <div class="navbar-logo">
          <router-link @click="drawer = !drawer" to="/"><img :src="require('@/assets/logo.jpg')" alt="logo" height="50" width="100"></router-link>
        </div>
        <div class="navbar-links d-flex flex-column align-center">
          <router-link class="d-flex align-center" @click="drawer = !drawer" v-for="link of navbarLinks" :key="link" :to="link.href">{{ link.name }}</router-link>
        </div>
        <div class="navbar-contacts flex-column" v-for="contact of navbarContacts" :key="contact">
          <a class="d-flex align-center" :href="contact.href">
            <v-icon
                :icon="contact.icon"
            ></v-icon>
            {{ contact.text }}
          </a>
        </div>
      </div>
    </transition>
  </div>
</template>

<script>
export default {
  name: "AppNavbar",
  data () {
    return {
      navbarLinks: [
        {name: 'Главная', href: '/'},
        {name: 'Направления', href: '/services'},
        {name: 'Новости компании', href: '/news'},
        {name: 'Контакты', href: '/contacts'}
      ],
      navbarContacts: [
        {text: '8 (383) 209-19-16', href: 'tel:83832091916', icon: 'mdi-phone'}
      ],
      drawer: false
    }
  },
}
</script>

<style scoped lang="sass">
.router-link-active
  background: rgba(255, 255, 255, 0.04)
  border-bottom: 6px solid #0047CD !important
.navbar
  background: rgba(0, 0, 0, 0.85)
  height: 72px
  &-logo
    .router-link-active
      background: unset
      border-bottom: none !important
    a
      img
        width: 200px
  &-links
    .navbar-link
      color: #ffffff
      text-decoration: none
      font-family: "Montserrat Medium"
      font-weight: 400
      font-size: 20px
      line-height: 24px
      padding: 0 8px
      border-top: 6px solid transparent
      border-bottom: 6px solid transparent
      transition: 0.4s all
      &:hover
        background: rgba(255, 255, 255, 0.04)
        border-bottom: 6px solid #0047CD
  &-contacts
    a
      color: #ffffff
      text-decoration: none
      font-family: "Montserrat Light"
      font-weight: 200
      font-size: 20px
      line-height: 24px
      .v-icon
        margin-right: 15px
.mobile-navbar
  .navbar-logo__mobile
    position: fixed
    left: 16px
    top: 24px
    z-index: 50
  &__hamburger
    height: 44px
    width: 44px
    padding: 10px
    position: fixed
    right: 16px
    top: 33px
    background: rgba(0, 0, 0, 0.4)
    border-radius: 4px
    cursor: pointer
    z-index: 50
    span
      background: #ffffff
      height: 2px
      width: 100%
      border-radius: 2px
  &__close
    height: 44px
    width: 44px
    padding: 10px
    position: fixed
    right: 16px
    top: 33px
    cursor: pointer
    z-index: 1000
    span
      background: #ffffff
      height: 2px
      border-radius: 2px
      &:first-child
        transform: rotate(45deg)
        position: absolute
        width: 80%
        top: 50%
      &:last-child
        transform: rotate(-45deg)
        position: absolute
        width: 80%
        top: 50%
  &__drawer
    background: #000000
    position: fixed
    right: 0
    width: 100%
    height: 100vh
    z-index: 100
    .navbar-logo
      margin-top: 24px
      .router-link-active
        background: unset
        border-bottom: none !important
    .navbar-links
      margin-top: 60px
      a
        text-decoration: none
        color: #ffffff
        font-family: "Montserrat Medium"
        border-top: 6px solid transparent
        border-bottom: 6px solid transparent
    .navbar-contacts
      margin-top: 60px

.slide-fade-enter-active
  transition: all .3s ease-out

.slide-fade-leave-active
  transition: all .8s cubic-bezier(1.0, 0.5, 0.8, 1.0)

.slide-fade-enter-from,
.slide-fade-leave-to
  transform: translateX(20px)
  opacity: 0
@media (max-width: 1279px)
  .navbar
    &-links
      .navbar-link
        font-size: 18px
    &-contacts
      a
        font-size: 18px
      .v-icon
        margin-right: 10px
    &-logo
      a
        img
          width: 100px
@media (max-width: 959px)
  .mobile-navbar
    &__drawer
      width: 50%
@media (max-width: 569px)
  .mobile-navbar
    &__drawer
      width: 100%
</style>