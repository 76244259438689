import { createStore } from 'vuex'
import auth from './modules/auth'
import services from './modules/servicesNew'
import news from './modules/newsNew'
import imageUpload from './modules/imageUpload'

export default createStore({
  state () {
    return {
      error: null
    }
  },
  getters: {
    error: s => s.error
  },
  mutations: {
    setError (state, error) {
      state.error = error
    },
    clearError (state) {
      state.error = null
    }
  },
  modules: {
    auth, services, news, imageUpload
  }
})
