<template>
  <app-preview></app-preview>
  <app-services v-if="!loading" :services="services"></app-services>
  <app-about></app-about>
</template>

<script>
import { defineComponent } from 'vue';

// Components
import AppPreview from "@/components/AppPreview";
import AppServices from "@/components/AppServices";
import AppAbout from "@/components/AppAbout";

export default defineComponent({
  name: 'HomeView',
  data () {
    return {
      services: [],
      loading: true
    }
  },

  async mounted () {
    this.services = await this.$store.dispatch('fetchServices')
    this.loading = false
  },

  computed: {
    error () {
      return this.$store.getters.error
    }
  },

  components: {
    AppPreview,
    AppServices,
    AppAbout,
  },
});
</script>
