<template>
  <div class="contacts-wrapper">
    <v-container>
      <v-row>
        <v-col
            offset-lg="1"
            offset="0"
            lg="5"
            cols="12"
        >
          <v-form
              lazy-validation
              class="form"
          >
            <div class="form-title">Оставьте заявку, наш специалист свяжется в вами:</div>
            <v-text-field
                bg-color="#000000"
                label="Контактное лицо"
                v-model="name"
                prepend-inner-icon="mdi-account"
                :rules="[rules.required]"
            ></v-text-field>
            <v-text-field
                bg-color="#000000"
                label="E-mail"
                v-model="email"
                prepend-inner-icon="mdi-email"
                :rules="[rules.required, rules.email]"
            ></v-text-field>
            <v-text-field
                bg-color="#000000"
                label="Телефон"
                v-model="phone"
                prepend-inner-icon="mdi-phone"
                :rules="[rules.required, rules.phone]"
            ></v-text-field>
            <div class="d-flex align-start mb-sm-0 mb-3">
              <v-checkbox
                  v-model="checkbox"
                  hide-details
                  class="mr-xl-3 mr-lg-6 mr-sm-3 mr-6"
              ></v-checkbox>
              <span>Я даю свое согласение на обработку персональных данных согласно Политике конфиденциальности</span>
            </div>
            <vue-recaptcha class="captcha" ref="recaptcha" sitekey="6LeGaLkhAAAAACZDmppbWig5utIneQ2AWqAFOmaP" @expired="onCaptchaExpired" @verify="captchaVerify"></vue-recaptcha>
            <v-btn class="form-btn d-flex" :disabled="!checkbox" @click.prevent="sendForm">Отправить</v-btn>
          </v-form>
        </v-col>
        <v-col
            lg="5"
            cols="12"
        >
          <div class="contacts">
            <div class="contacts-title">Наши контакты: <div></div></div>
            <a :href="contact.href" class="contacts-item" v-for="contact of contacts" :key="contact"><span>{{ contact.name }}</span> {{ contact.info }}</a>
          </div>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import axios from "axios";
import {BASE_URL} from "../../server/keys";
import { VueRecaptcha } from 'vue-recaptcha';

export default {
  name: "AppForm",
  components: { VueRecaptcha },
  data () {
    return {
      checkbox: false,
      contacts: [
        {name: 'Склад:', info: '630038, г. Новосибирск, ул.Твардовского 1 Б, офис 20'},
        {name: 'Склад:', info: 'ул. Фабричная 35А'},
        {name: 'Email:', info: 'llcecofreight@gmail.com', href: 'mailto:llcecofreight@gmail.com'},
        {name: 'Телефон:', info: '8 (383) 209-19-16', href: 'tel:83832091916'}
      ],
      name: '',
      email: '',
      phone: '',
      captchaToken: '',
      rules: {
        required: value => !!value || 'Поле обязательное',
        email: value => {
          const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
          return pattern.test(value) || 'Нужно указать email'
        },
        phone: value => {
          const pattern = /^[\\+]?[(]?[0-9]{3}[)]?[-\s\\.]?[0-9]{3}[-\s\\.]?[0-9]{4,6}$/im
          return pattern.test(value) || 'Неправильный номер'
        }
      },
    }
  },
  methods: {
    async sendForm () {
      if (this.captchaToken) {
          await axios({
          url: BASE_URL + 'api/mail/send/main',
          method: 'post',
          data: {
            name: this.name,
            email: this.email,
            phone: this.phone,
            checkbox: this.checkbox
          }
        }).then(() => {
          this.name = null
          this.email = null
          this.phone = null
          this.checkbox = false
          this.$notify({ type: 'success', text: 'Сообщение отправлено, вскоре с вами свяжемся' })
        }).catch((err) => {
          console.log(err)
          this.$notify({ type: 'error', text: 'Сообщение не отправлено, попробуйте еще раз' })
        })
      }
    },
    captchaVerify(response) {
      this.captchaToken = response;
    },
    onCaptchaExpired () {
      this.$refs.recaptcha.reset();
      this.captchaToken = ''
    }
  }
}
</script>

<style scoped lang="sass">
.contacts-wrapper
  background-color: #000000
  padding-top: 60px
.v-input
  font-family: 'Montserrat Regular' !important
.form-map
  border-radius: 8px
.form
  background: #212121
  padding: 44px
  border-radius: 8px
  color: rgba(255, 255, 255, 0.5)
  &-title
    font-family: 'Montserrat Regular'
    font-style: normal
    font-weight: 500
    font-size: 16px
    line-height: 20px
    color: rgba(245, 245, 245, 0.7)
    margin-bottom: 24px
  &-policy
    span
      font-family: 'Montserrat Regular'
      font-style: normal
      font-weight: 400
      font-size: 13px
      line-height: 16px
      color: rgba(0, 0, 0, 0.5)
  &-btn
    background-color: #0047CD
    font-family: 'Montserrat Regular'
    font-style: normal
    font-weight: 400
    font-size: 20px
    line-height: 26px
    color: #FFFFFF
    padding: 25px 60px
.contacts
  background: url("@/assets/images/contacts/contacts-img.png") center center/cover no-repeat
  height: 100%
  padding-top: 90px
  border-radius: 6px
  display: flex
  flex-direction: column
  &-title
    display: flex
    flex-direction: column
    align-items: center
    font-family: 'Montserrat Medium'
    font-style: normal
    font-weight: 600
    font-size: 32px
    line-height: 39px
    text-align: center
    color: #FFFFFF
    div
      width: 130px
      height: 4px
      background-color: #0047CD
      border-radius: 2px
  &-item
    font-family: 'Montserrat Regular'
    font-style: normal
    font-weight: 400
    font-size: 20px
    line-height: 24px
    text-align: center
    color: #FFFFFF
    margin-top: 32px
    text-decoration: none
    span
      font-family: "Montserrat Medium"
      font-weight: 600
      text-transform: uppercase
@media (max-width: 1279px)
  .form-map
    height: 522px
  .contacts
    padding-bottom: 50px
    padding-top: 50px
@media (max-width: 959px)
  .contacts
    &-title
      font-size: 28px
    &-item
      font-size: 18px
@media (max-width: 599px)
  .captcha
    transform: scale(0.77)
    -webkit-transform: scale(0.77)
    transform-origin: 0 0
    -webkit-transform-origin: 0 0
  .form
    padding: 20px
  .form-map
    height: 350px
  .contacts
    padding: 30px 10px
    &-title
      font-size: 24px
    &-item
      font-size: 16px
</style>