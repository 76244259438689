<template>
  <v-app>
    <app-navbar v-if="!isAdmin"></app-navbar>
    <v-main>
      <router-view></router-view>
    </v-main>
    <app-form v-if="!isAdmin && !isPolicy"></app-form>
    <app-footer v-if="!isAdmin"></app-footer>
  </v-app>
  <notifications/>
</template>

<script>
import AppNavbar from "@/components/AppNavbar";
import AppFooter from "@/components/AppFooter";
import AppForm from "@/components/AppForm";

export default {
  name: 'App',
  components: {
    AppNavbar,
    AppFooter,
    AppForm
  },
  computed: {
    isAdmin () {
      return this.$route.path.includes('/admin')
    },
    isPolicy () {
      return this.$route.path.includes('/policy')
    }
  }
}
</script>
