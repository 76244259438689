import {doc, addDoc, deleteDoc, getDocs, getDoc, collection, updateDoc, where, query} from 'firebase/firestore'
import {ref, getDownloadURL} from 'firebase/storage'
import {db, storage} from '@/firebase'
import { rusToLat } from '../../plugins/stringtranslate'

export default {
  actions: {
    //получаем массив со всеми услугами
    async fetchServices({commit}) {
      try {
        const querySnapshot = await getDocs(collection(db, "services"));
        const services = querySnapshot.docs.map((d) => ({...d.data(), id: d.id}));
        const downloadImageUrlsRequests = services.map(x => getDownloadURL(ref(storage, x.image)));
        const urls = await Promise.all(downloadImageUrlsRequests);
        return services.map((n, i) => ({...n, imageUrl: urls[i]}));
      } catch (e) {
        commit('setError', e)
        throw e
      }
    },
    //получаем массив услуг с фильтром по title
    // eslint-disable-next-line no-unused-vars
    async fetchServiceByName({commit}, name) {
      try {
        const servicesRef = collection(db, "services");
        const q = query(servicesRef, where("nameEng", "==", name));
        const querySnapshot = await getDocs(q);
        const services = querySnapshot .docs.map((d) => ({...d.data(), id: d.id}));
        const downloadImageUrlsRequests = services.map(x => getDownloadURL(ref(storage, x.image)));
        const urls = await Promise.all(downloadImageUrlsRequests);
        return services.map((n, i) => ({...n, imageUrl: urls[i]}))?.[0] || null;
      } catch (e) {
        commit('setError', e)
        throw e
      }
    },
    async fetchServiceById({commit}, id) {
      try {
        const docRef = doc(db, "services", id);
        const docSnap = await getDoc(docRef);
        const service = docSnap.data();
        const imageUrl = await getDownloadURL(ref(storage, service.image));
        return {...service, imageUrl}
      } catch (e) {
        commit('setError', e)
        throw e
      }
    },
    // eslint-disable-next-line no-unused-vars
    async updateService({commit, dispatch}, {id, docData}) {
      const docRef = doc(db, "services", id);
      try {
        await updateDoc(docRef, {...docData, nameEng: rusToLat(docData.name)});
        return {...docData, id,  nameEng: rusToLat(docData.name)}
      } catch (e) {
        commit('setError', e)
        throw e
      }
    },
    //создание услуги с автоматическим присвоением id. Если требуется указать свой id, тогда используйте setDoc
    async createService({commit}, docData) {
      try {
        const docRef = await addDoc(collection(db, "services"), {...docData, nameEng: rusToLat(docData.name)});
        return {...docData, id: docRef.id, nameEng: rusToLat(docData.name)}
      } catch (e) {
        commit('setError', e)
        throw e
      }
    },
    async deleteService({commit}, id) {
      try {
        await deleteDoc(doc(db, "services", id));
      } catch (e) {
        commit('setError', e)
        throw e
      }
    }
  }
}
