import { createRouter, createWebHistory } from 'vue-router'
import HomeView from '../views/HomeView.vue'
import AdminView from "@/views/AdminView"
import { auth } from '@/firebase'

const routes = [
  {
    path: '/',
    name: 'home',
    component: HomeView,
    meta: { title: 'ЭКО-ФРЕЙТ' }
  },
  {
    path: '/policy',
    name: 'policy',
    meta: { title: 'Политика конфиденциальности'},
    component: () => import('../views/PolicyView')
  },
  {
    path: '/contacts',
    name: 'contacts',
    meta: { title: 'Контакты' },
    component: () => import('../views/ContactsView.vue')
  },
  {
    path: '/news',
    name: 'news',
    meta: { title: 'Новости' },
    component: () => import('../views/NewsView')
  },
  {
    path: '/services',
    name: 'services',
    meta: { title: 'Направления' },
    component: () => import('../views/ServicesView')
  },
  {
    path: '/services/:service',
    meta: { title: 'Направления' },
    component: () => import('../layouts/ServicePageView')
  },
  {
    path: '/login',
    name: 'login',
    meta: { title: 'Вход в административную панель' },
    component: () => import('../views/LoginView')
  },
  {
    path: '/admin',
    name: 'admin',
    component: AdminView,
    meta: { auth: true, title: 'Административная панель' },
    children: [
      {
        path: 'edit-service',
        name: 'edit-service',
        meta: { title: 'Редактирование услуги' },
        props: true,
        component: () => import('../views/EditServiceView')
      },
      {
        path: 'add-service',
        name: 'add-service',
        meta: { title: 'Добавление услуги' },
        component: () => import('../views/AddServiceView')
      },
      {
        path: 'add-news',
        name: 'add-news',
        meta: { title: 'Добавление новости' },
        component: () => import('../views/AddNewsView')
      },
      {
        path: 'edit-news',
        name: 'edit-news',
        meta: { title: 'Редактирование новости' },
        props: true,
        component: () => import('../views/EditNewsView')
      }
    ]
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

router.beforeEach((to, from, next) => {
  document.title = to.meta.title
  const currentUser = auth.currentUser
  const requireAuth = to.matched.some(record => record.meta.auth)

  if (requireAuth && !currentUser) {
    next('/login?message=login')
  } else {
    next()
  }
})

export default router
