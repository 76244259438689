<template>
  <footer class="footer">
    <v-container>
      <v-row>
        <v-col
        lg="10"
        offset-lg="1"
        cols="12"
        >
          <div class="divider"></div>
        </v-col>
        <v-col
        offset-lg="1"
        md="2"
        cols="12"
        class="d-flex flex-column align-start align-md-start align-sm-center"
        >
          <div class="footer-logo"><img :src="require('@/assets/logo.jpg')" alt="logo" height="50" width="130"></div>
        </v-col>
        <v-col
        md="5"
        cols="12"
        class="d-flex flex-column align-start align-md-start align-sm-center"
        >
          <div class="footer-contacts footer-text">
            <a :href="contact.href" class="footer-contacts__item text-md-left text-sm-center text-left" v-for="contact of contacts" :key="contact">{{ contact.name }} {{ contact.info }}</a>
          </div>
        </v-col>
        <v-col
        lg="3"
        md="5"
        cols="12"
        class="d-flex flex-column align-start align-md-start align-sm-center"
        >
          <div class="footer-info footer-text">
            <div class="footer-info__item text-md-left text-sm-center text-left" v-for="info of footerInfo" :key="info">{{ info }}</div>
            <router-link class="footer-info__policy text-md-left text-sm-center text-left" to="/policy">Политика конфиденциальности</router-link>
            <div class="footer-info__item text-md-left text-sm-center text-left">© 2022 Эко-Фрейт.</div>
          </div>
        </v-col>
      </v-row>
    </v-container>
  </footer>
</template>

<script>
export default {
  name: "AppFooter",
  data () {
    return {
      contacts: [
        {name: 'Склад:', info: '630038, г. Новосибирск, ул.Твардовского 1 Б, офис 20'},
        {name: 'Склад:', info: 'ул. Фабричная 35А'},
        {name: 'Email:', info: 'llcecofreight@gmail.com', href: 'mailto:llcecofreight@gmail.com'},
        {name: 'Телефон:', info: '8 (383) 209-19-16', href: 'tel:83832091916'}
      ],
      footerInfo: ['ОГРН 1145476151996', 'ИНН 5406795734']
    }
  }
}
</script>

<style scoped lang="sass">
.footer-text
  display: flex
  flex-direction: column
  font-family: 'Montserrat Light'
  font-style: normal
  font-weight: 300
  font-size: 20px
  line-height: 24px
  color: #FFFFFF
.footer
  background: #000000
  .divider
    background: rgba(245, 245, 245, 0.6)
    width: 100%
    height: 2px
    border-radius: 2px
    margin-top: 60px
  &-contacts
    &__item
      margin-bottom: 16px
      text-decoration: none
      font-family: 'Montserrat Light'
      font-style: normal
      font-weight: 300
      font-size: 20px
      line-height: 24px
      color: #FFFFFF
  &-info
    &__item
      margin-bottom: 16px
      font-size: 16px
      line-height: 20px
    &__policy
      display: block
      font-family: 'Montserrat Light'
      font-style: normal
      font-weight: 300
      font-size: 16px
      line-height: 20px
      color: #FFFFFF
      margin-bottom: 16px
@media (max-width: 599px)
  .footer-text
    font-size: 16px
    line-height: 20px
  .footer
    &-contacts
      &__item
    &-info
      &__item
        font-size: 14px
        line-height: 30px

</style>