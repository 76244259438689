<template>
  <div class="about-wrapper">
    <v-container
    fluid
    class="pt-0 pb-3 pr-sm-0 pl-sm-0 mt-sm-16 mt-10"
    >
      <v-row
      class="w-100 mx-0"
      >
        <v-col
            offset-lg="1"
            lg="4"
            offset-md="1"
            md="5"
            cols="12"
            offset="0"
            class="order-md-1 order-2 pl-6 pr-6"
        >
          <div class="about-title">
            О компании
            <div></div>
          </div>
          <div class="about-subtitle">
            ПОЧЕМУ ВЫБИРАЮТ НАС <br><br>
            Нас выбирают потому что мы стараемся делать работу для наших клиентов хорошо. Работая по разным направлениям, мы неизменно ставим во главу угла качество. Это формирует доверие и наши клиенты становятся нашими долгосрочными партнерами
            <br> <br>
            КАЧЕСТВО <br> <br>
            ДОВЕРИЕ <br> <br>
            ПАРТНЕРСТВО
          </div>
        </v-col>
        <v-col
            offset-lg="1"
            lg="6"
            offset-md="0"
            md="6"
            cols="12"
            offset="0"
            class="pb-sm-0 pt-sm-0 px-sm-0 order-md-2 order-1"
        >
          <div class="about-img"></div>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
export default {
  name: "AppAbout",
  // props: {
  //   alignToTop: {
  //     required: false,
  //     default: null,
  //     type: Boolean,
  //   },
  //   options: {
  //     required: false,
  //     default: null,
  //     type: Object,
  //   },
  // },
  // methods: {
  //   scrollTo () {
  //     this.$refs.component.scrollIntoView(this.options !== null ? this.options : this.alignToTop);
  //   },
  // },
}
</script>

<style scoped lang="sass">
.about-wrapper
  background: url("@/assets/images/about/about-bg.png")
.about-title
  font-family: 'Manrope'
  font-style: normal
  font-weight: 700
  font-size: 32px
  line-height: 44px
  text-transform: uppercase
  color: #FFFFFF
  margin-top: 60px
  margin-bottom: 15px
  div
    width: 90px
    height: 4px
    background-color: #0047CD
    border-radius: 2px
.about-subtitle
  font-family: 'Montserrat Regular'
  font-style: normal
  font-weight: 400
  font-size: 20px
  line-height: 25px
  color: #FFFFFF
  padding-bottom: 50px
.about-img
  width: 100%
  height: 100%
  background: url("@/assets/images/about/about-img.png") center center no-repeat
  background-size: cover
@media (max-width: 1279px)
  .about-subtitle
    font-size: 18px
@media (max-width: 959px)
  .about-title
    font-size: 26px
  .about-subtitle
    padding-bottom: 30px
  .about-img
    height: 480px
@media (max-width: 599px)
  .about-title
    font-size: 20px
    line-height: 27px
    margin-top: 30px
  .about-subtitle
    font-size: 15px
    line-height: 25px
    padding-bottom: 15px
  .about-img
    height: 230px
</style>