import {ref, uploadBytes, deleteObject} from 'firebase/storage'
import {storage} from '@/firebase'

export default {
  actions: {
    async uploadImage({commit}, {imageFile, imageUrl}) {
      try {
        const imageRef = ref(storage, imageUrl);
        console.log(imageFile, imageUrl)
        await uploadBytes(imageRef, imageFile);
      } catch (e) {
        commit('setError', e)
        throw e
      }
    },
    async deleteImage({commit}, imageUrl) {
        try {
          const imageRef = ref(storage, imageUrl);
          await deleteObject(imageRef);
        } catch (e) {
          commit('setError', e)
          throw e
        }
      },
  }
}
